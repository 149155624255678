
import {defineComponent, onMounted} from "vue";
import {MenuComponent} from "@/assets/ts/components";
import ApiService from "@/core/services/ApiService";
import {Api} from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "ActionFactoryDropdown",
  components: {},
  props: {
    factoryId: {type: Number, default: 0}
  },
  setup() {
    onMounted(() => {
      MenuComponent.reinitialization();
    });
  },
  methods: {
    downloadExcel(type) {
      let fileLink = document.createElement('a');

      switch (type) {
        case 'active':
          ApiService.get(Api.GET_FACTORY_ACTIVE_EXCEL, {'factory_id': this.$props.factoryId}).then(({data}) => {
            fileLink.href = 'data:application/vnd.ms-excel;base64,' + data ;
            fileLink.download = 'aktivni_zamestnanci.xlsx'
            document.body.appendChild(fileLink);
            fileLink.click();
            fileLink.remove()
          }).catch(() => {
            console.log("sakra")
          })
          break;
      }


    },
  }
});
