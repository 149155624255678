
import {defineComponent} from "vue";
import {useStore} from "vuex";
import router from "@/router";
import {Actions, Api} from "@/store/enums/StoreEnums";
import {MenuComponent} from "@/assets/ts/components";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import ActionDropdown from "@/views/factory/components/ActionDropdown.vue";


export default defineComponent({
  name: "KtDetailFactory",
  components: { ActionDropdown},

  data() {
    return {
      factory: {} as any,
      isLoaded: false,
      stats: {},
      store: useStore()
    }
  },
  created() {
    this.loadData();
    setCurrentPageBreadcrumbs("Detail střediska", ["Střediska"]);
  },
  methods: {
    loadData() {
      this.store.dispatch(Actions.GET_FACTORY, this.$route.params.idFactory).then(()=> {
        this.factory = this.store.getters.actualFactory
        this.isLoaded = true;
        MenuComponent.reinitialization();
        this.getStats()
      }).catch(() => {
        router.push({name: '500'});
      });
    },

    getStats() {
      ApiService.get(Api.OVERVIEW_FACTORY_STATS, {'factory_id': this.factory.id}).then(response => {
        this.stats = response.data
      })
    }
  }
});
